<template>
  <div class="flex h-full">
    <loader :loading="loading" :backdrop="true"/>
    <div class="side-panel">
      <DetailsSidePanel>
        <template v-slot:heading>
          <span v-if="isClone">
            new version of authorized data block {{name}} for {{type}}
          </span>
          <span v-else>
            authorized data block for {{type}}
          </span>
        </template>
        <template v-slot:text>
          shared authorized data block can include records with a preregistered shared data key only.
        </template>
      </DetailsSidePanel>
    </div>
    <div class="w-2/3">
      <data-block-form
        :initialValues="record"
        :on-submit="handleSubmit"
        @close="() => redirectTo(basePath, {type})"
      />
    </div>
  </div>
</template>

<script>
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ActionsMixin from "@/mixins/ActionsMixin";
  import Loader from '@/components/ui/Loader';
  import DetailsSidePanel from '@/components/auth/details/DetailsSidePanel';
  import DataBlockForm from "@/views/auth/raa-devtools/relationship-types/data-block/DataBlockForm";

  export default {
  name: 'DataBlockCreate',
  mixins: [ModalNavigation, NotifyMixin, ActionsMixin],
  components: {
    Loader,
    DataBlockForm,
    DetailsSidePanel,
  },
  data() {
    return {
      record: {},
      loading: false,
    };
  },
  computed: {
    basePath() {
      return this.$route.name.replace(`dataBlock.${this.isClone ? 'clone' : 'create'}`, 'details');
    },
    basePathEdit() {
      return this.$route.name.replace('clone', 'edit');
    },
    type: function () {
      return this.$route.params.type;
    },
    name() {
      return this.$route.params.name;
    },
    version() {
      return this.$route.params.version;
    },
    isClone() {
      return Boolean(this.name && this.version);
    },
  },
  methods: {
    handleSubmit({ name, description, records }) {
      if (!this.loading) {
        this.loading = true;

        return this.$raaDataProvider.create('sharedDataBlocks', { data: { name, description, records }, type: this.type })
          .then(() => {
            this.redirectTo(this.basePath, {type: this.type});

            this.notifySuccess(`Data block ${name} has been created`);
          })
          .catch(error => this.notifyError(error.message))
          .finally(() => this.loading = false);
      }
    },
    redirectTo(pathName, params = {}) {
      this.$router.replace({
        name: pathName,
        params: {direction: 'back', ...params}
      });
    },
    fetchSharedDataBlock() {
      this.loading = true;
      this.$raaDataProvider.get('sharedDataBlockVersioned', { type: this.type, name: this.name, version: this.version })
        .then(result => {
          this.record = result;
        })
        .catch(error => {
          this.redirectTo(this.basePathEdit, this.$route.params);
          this.notifyError(error.message);
        })
        .finally(() => this.loading = false);
    },
  },
  mounted() {
    if (this.isClone) {
      this.fetchSharedDataBlock();
      this.setActiveModalTitle(`duplicate data block ${this.name}`)
    }
  },
};
</script>
