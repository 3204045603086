<template>
  <Form
    :submit="handleSubmit"
    :initialValues="initialValues"
    class="w-full"
  >
    <div class="form-narrow">
      <div class="form-row">
        <TextField name="name" label="name*" :editMode="!Boolean(initialValues.name)" :validate="[required, alphaNumericKey, maxLength(100)]" />
        <TextField v-if="editMode" name="version" label="version" :editMode="false" />
      </div>
      <div class="form-row mb-4">
        <TextField name="description" label="description*" :validate="[required, maxLength(400)]" />
      </div>
      <editable-list
        ref="list"
        :data="records"
        :fields="fields"
        titleClass="label flex items-center"
      >
        <template v-slot:title>
          <div class="form-col"><label>data records</label></div>
        </template>
        <template v-slot:row-template>
          <data-record-form :dataKeyOptions="sharedDataKeysOptions" :on-submit="add" @cancel="cancel"/>
        </template>
        <template v-slot:actions="actionsScope">
          <button
            class="btn-action-warning focus:outline-none"
            type="button"
            v-if="!editMode || !originalKeys.includes(actionsScope.record.key)"
            @click.stop="remove(actionsScope.index)"
          >
            <icon class="w-5 h-5" name="close"/>
          </button>
        </template>
      </editable-list>
      <ModalFooter :footer="footer" :tertiary="cancelForm" />
    </div>
  </Form>
</template>

<script>
  import {cloneDeep} from 'lodash-es';
  import ModalNavigation from "@/mixins/ModalNavigation";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import DataRecordMixin from '@/components/auth/data_block/DataRecordMixin';
  import Icon from "@/components/ui/Icon";
  import Form from "@/components/form/Form";
  import TextField from "@/components/form/TextField";
  import EditableList from "@/components/ui/inlineediting/EditableList";
  import ModalFooter from "@/components/ui/modals/ModalFooter";
  import TextEditableField from "@/components/auth/list/fields/TextEditableField";
  import DataRecordForm from "@/components/auth/data_block/DataRecordForm";

  export default {
  name: 'DataBlockForm',
  mixins: [
    ModalNavigation,
    NotifyMixin,
    DataRecordMixin,
  ],
  components: {
    DataRecordForm,
    Icon,
    Form,
    TextField,
    EditableList,
    ModalFooter,
  },
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
    initialValues: {
      type: Object,
      default: () => ({})
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      records: [],
      fields: [
        {
          name: 'key',
          title: 'key',
          titleClass: 'relative pr-2',
          dataClass: 'pr-2',
          width: '40%'
        },
        {
          name: TextEditableField,
          title: 'value',
          titleClass: 'relative px-2',
          dataClass: 'px-2',
          width: '50%',
          switch: {
            fieldName: 'value',
            getPlaceholder: this.getValuePlaceholder,
            getValidator: this.getValueValidator,
          }
        },
        {
          name: 'actions',
          title: '',
          titleClass: 'relative px-2',
          dataClass: 'px-2',
          width: '10%',
        }
      ],
      footer: {
        primaryButton: 'save',
        tertiaryButton: 'cancel'
      },
    };
  },
  computed: {
    type: function () {
      return this.$route.params.type;
    },
    originalKeys() {
      return this.initialValues.records?.map(({ key }) => key) || [];
    },
    recordKeys() {
      return this.records.map(({ key }) => key);
    },
  },
  watch: {
    'initialValues.records': {
      handler: function (data = []) {
        this.records = cloneDeep(data);
      },
      immediate: true
    },
  },
  methods: {
    add({ key, value }) {
      const newDataKey = key.value;
      if (this.recordKeys.includes(newDataKey.key)) {
        this.notifyError(`The data key ${newDataKey.key} has been already added`);

      } else {
        this.records.push({ ...newDataKey, keyId: newDataKey.id, value});
        this.$refs.list.hideNewRow();
      }
    },
    cancel() {
      this.$refs.list.hideNewRow();
    },
    remove(index) {
      this.records.splice(index,1);
    },
    handleSubmit(values) {
      this.onSubmit({
        ...values,
        records: this.records.map(({ keyId, value }) => ({
          keyId,
          value: !value || value === 'null' ? null : value
        }))
      });
    },
    cancelForm(e) {
      e.preventDefault();
      this.close();
    }
  },
};
</script>
